import React from 'react';

export default function Footer() {
  return (
    <footer>
      <div className="container">
        <p>Liftup All Rights Reserved.</p>
        <p>Photo by <a href="https://unsplash.com/@danielapodaca96?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Daniel Apodaca</a> on <a href="https://unsplash.com/s/photos/workout?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a></p>
        <ul className="list-inline">
          <li className="list-inline-item">
            <a href="/#">Privacy</a>
          </li>
          <li className="list-inline-item">
            <a href="/#">Terms</a>
          </li>
          <li className="list-inline-item">
            <a href="/#">FAQ</a>
          </li>
        </ul>
      </div>
    </footer>
  );
}
